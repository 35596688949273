let initialState = {
    username: localStorage.getItem('username') ? JSON.parse(localStorage.getItem('username')):'usernameDefault',
    role: localStorage.getItem('role') ? JSON.parse(localStorage.getItem('role')):0,
    id_subcabang: localStorage.getItem('id_subcabang') ? JSON.parse(localStorage.getItem('id_subcabang')):'',
    id_cabang: localStorage.getItem('id_cabang') ? JSON.parse(localStorage.getItem('id_cabang')):'',
}

const reducer = (state = initialState, action)=>{
    if(action.type === 'save'){
        localStorage.setItem('username', JSON.stringify(action.username))
        localStorage.setItem('role', JSON.stringify(action.role))
        localStorage.setItem('id_subcabang', JSON.stringify(action.id_subcabang))
        localStorage.setItem('id_cabang', JSON.stringify(action.id_cabang))
        return {
            ...state,
            username:action.username,
            role:action.role,
            id_subcabang:action.id_subcabang,
            id_cabang:action.id_cabang,
        }
    }
    if(action.type === 'remove'){
        return {
            ...state,
            username:"",
            role:0,
            id_subcabang: '',
            id_cabang:''
        }
    }
    return state
}


export default reducer;