import React, { Component,Suspense,lazy } from 'react';
import { Switch, Route,Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';



const Cabang = lazy(() => import('../view/Cabang'));
const SubCabang = lazy(() => import('../view/SubCabang'));
const AdminCabang = lazy(() => import('../view/AdminCabang'));
const AdminSubCabang = lazy(() => import('../view/AdminSubCabang'));

const Buttons = lazy(() => import('./basic-ui/Buttons'));
const Login = lazy(() => import('../view/Login'));
const Dashboard = lazy(() => import('../view/Dashboard'));

const TransaksiOrder = lazy(() => import('../view/TransaksiOrder'));

const Topup = lazy(() => import('../view/TopUp'));
const TopupDetail = lazy(() => import('../view/TopUpDetail'));

const Withdraw = lazy(() => import('../view/Withdraw'));
const WithdrawDetail = lazy(() => import('../view/WithdrawDetail'));

const Voucher = lazy(() => import('../view/Voucher'));
const VoucherDetail = lazy(() => import('../view/VoucherDetail'));

const BankTopUp = lazy(() => import('../view/BankTopUp'));

const BankTopUpDetail = lazy(() => import('../view/BankTopUpDetail'));

const Admin = lazy(() => import('../view/Admin'));
const AdminDetail = lazy(() => import('../view/AdminDetail'));

const SkemaGaji = lazy(() => import('../view/SkemaPendapatan'));
const SkemaGajiDetail = lazy(() => import('../view/SkemaPendapatanDetail'));


const Harga = lazy(() => import('../view/Harga'));
const HargaDetail = lazy(() => import('../view/HargaDetail'));

const Driver = lazy(() => import('../view/Driver'));
const DriverDetail = lazy(() => import('../view/DriverDetail'));

const Customer = lazy(() => import('../view/Customer'));
const CustomerDetail = lazy(() => import('../view/CustomerDetail'));


const Pembayaran = lazy(() => import('../view/Pembayaran'));
const PembayaranDetail = lazy(() => import('../view/PembayaranDetail'));


const TransaksiOrderDetail = lazy(() => import('../view/TransaksiOrderDetail'));



class AppRoutes extends Component {
  render () {

   

    return (
      <Suspense fallback={<Spinner/>}>
        <Switch>
          <Route exact path="/login" component={ Login } />
          <Route path="/dashboard" component={ Dashboard } />  
          <Route exact path="/master-user/customer/edit/:id" render={(props) => (
              <CustomerDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-user/customer/:id" render={(props) => (
              <CustomerDetail id={props.match.params.id}/>
          )} />
          <Route path="/master-user/customer" component={ Customer } />
          <Route exact path="/master-user/admin/edit/:id" render={(props) => (
              <AdminDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-user/admin/:id" render={(props) => (
              <AdminDetail id={props.match.params.id}/>
          )} />
          <Route path="/master-user/admin" component={ Admin } />
          <Route path="/master-user/cabangadmin" component={ AdminCabang } />
          <Route path="/master-user/subcabangadmin" component={ AdminSubCabang } />
          
          <Route exact path="/master-user/driver/edit/:id/:id_subcabang" render={(props) => (
              <DriverDetail id={props.match.params.id} id_subcabang={props.match.params.id_subcabang}/>
          )} />
          <Route exact path="/master-user/driver/:id" render={(props) => (
              <DriverDetail id={props.match.params.id}/>
          )} />
          <Route path="/master-user/driver" component={ Driver } />


          <Route exact path="/pembayaran/detail/:id" render={(props) => (
              <PembayaranDetail id={props.match.params.id}/>
          )} />
          <Route path="/pembayaran" component={ Pembayaran } />
          <Route exact path="/master-app/bank/edit/:id" render={(props) => (
              <BankTopUpDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-app/bank/:id" render={(props) => (
              <BankTopUpDetail id={props.match.params.id}/>
          )} />
          <Route path="/master-app/bank" component={ BankTopUp } />
          <Route exact path="/master-app/kupon/edit/:id" render={(props) => (
              <VoucherDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-app/kupon/:id" render={(props) => (
              <VoucherDetail id={props.match.params.id}/>
          )} />
          <Route path="/master-app/kupon" component={ Voucher } />


          <Route exact path="/master-app/skema-gaji/edit/:id" render={(props) => (
              <SkemaGajiDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-app/skema-gaji/:id" render={(props) => (
              <SkemaGajiDetail id={props.match.params.id}/>
          )} />          
          <Route path="/master-app/skema-gaji" component={ SkemaGaji } />


    
          <Route exact path="/master-app/harga-jasa/edit/:id" render={(props) => (
              <HargaDetail id={props.match.params.id}/>
          )} />
          <Route exact path="/master-app/harga-jasa/:id" render={(props) => (
              <HargaDetail id={props.match.params.id}/>
          )} />          
          <Route path="/master-app/harga-jasa" component={ Harga } />

          <Route exact path="/transaksi/top-up-konfirmasi/detail/:id" render={(props) => (
              <TopupDetail id={props.match.params.id}/>
          )} />

        <Route exact path="/transaksi/wd-konfirmasi/detail/:id" render={(props) => (
              <WithdrawDetail id={props.match.params.id}/>
          )} />
          
          <Route exact path="/transaksi/order/detail/:id" render={(props) => (
              <TransaksiOrderDetail id={props.match.params.id}/>
          )} />

         


          <Route path="/transaksi/order" component={ TransaksiOrder } />
          
          <Route path="/transaksi/top-up-konfirmasi" component={ Topup } />
          
          <Route path="/transaksi/wd-konfirmasi" component={ Withdraw } />
          
          <Route path="/report" component={ Buttons } />
          <Route path="/kantor/cabang" component={ Cabang } />
          <Route path="/kantor/subcabang" component={ SubCabang } />

          

          <Redirect to="/login" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;