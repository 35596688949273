import React, { Component} from 'react';

import { Dropdown } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import axios from 'axios';
import { connect } from 'react-redux';

class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      StatusLogin : 'true',
    };
  }

  componentDidMount(){
    this.cek_autorization();
    this.ValidasiLogin();
    document.body.classList.toggle('sidebar-icon-only')

  }



  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  cek_autorization() {
    const URL_ = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;
    let URL = URL_+'cek_autorization'; 
    axios.post(URL,{
      
    },{
        headers : {
            Authorization : 'Bearer '+localStorage.getItem('wt'),
            Device_id : localStorage.getItem('d_i')
        }
    }).then((res)=>{
        if(res.data.status === 'need_login'){
         
            localStorage.removeItem('wt');
            localStorage.removeItem('d_i');
            this.setState({StatusLogin : false });
        }
  
    }).catch((err) =>{
        localStorage.removeItem('wt');
        localStorage.removeItem('d_i');

        this.setState({StatusLogin : false});
    });
    
  
}

    ValidasiLogin()
    { 

      let token = localStorage.getItem('wt');
    
      if(token === null){
        this.setState({StatusLogin : false});
      }
    }

 
  render () {

    

    const Logout = (e) => {
      e.preventDefault();
      localStorage.clear();

      this.setState({StatusLogin : false});
    }  

   

   

    return (
     
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo" to="/"><img src={process.env.REACT_APP_LOGO_COMPANY} alt="logo" /></Link>
          <Link className="navbar-brand brand-logo-mini" to="/"><img src={process.env.REACT_APP_LOGO_COMPANY} alt="logo" /></Link>
         

          {this.state.StatusLogin === false ? (<Redirect to='/login'/>) : ''} 
        </div>
      
        <div className="navbar-menu-wrapper d-flex align-items-stretch">
          <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={ () => document.body.classList.toggle('sidebar-icon-only') }>
            <span className="mdi mdi-menu"></span>
          </button>
          
          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item nav-profile">
              <Dropdown alignRight>
                <Dropdown.Toggle className="nav-link">
                  
                  <div className="nav-profile-text">
                    <p className="mb-1 text-black"><Trans>{this.props.state.username || 'Muhammad Yasin'} </Trans></p>
                  </div>
                </Dropdown.Toggle>

                <Dropdown.Menu className="navbar-dropdown">
                  
                  <Dropdown.Item href="!#" onClick={ Logout }>
                    <i className="mdi mdi-logout mr-2 text-primary"></i>
                    <Trans>Logout</Trans>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
            
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={this.toggleOffcanvas}>
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    );
  }

}

const mapStateToProps = (state) =>{
  return{
    state
  }
}

export default connect(mapStateToProps)(Navbar)