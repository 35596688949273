import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import axios from 'axios';



class Sidebar extends Component {

   constructor(props) {
    super(props);
    this.state = {
      isAdmin: false,
      isCabang: false,
      isSubcabang: false,
      sidebarPath:{}
    };
  }

  componentDidMount() {
    this.role()
    this.onRouteChanged();
    
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      
      el.addEventListener('mouseover', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function() {
        if(body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  toggleMenuState(menuState) {
   
    if (this.state?.sidebarPath[`${menuState}`]) {
      // this.setState({[menuState] : false});
      this.setState(function(prev){
        return {
          ...prev,
          sidebarPath:{
            ...prev.sidebarPath,
            [`${menuState}`]:false
          }
        }
      });

    } else if(Object.keys(this.state.sidebarPath).length === 0) {

      // this.setState({[menuState] : true});
      this.setState(function(prev){
        return {
          ...prev,
          sidebarPath:{
            ...prev.sidebarPath,
            [`${menuState}`]:true
          }
        }
      });
    } else {
      
      Object.keys(this.state.sidebarPath).forEach(i => {
        // this.setState({[i]: false});
        this.setState(function(prev){
          return {
            ...prev,
            sidebarPath:{
              ...prev.sidebarPath,
              [`${i}`]:false
            }
          }
        });
      });
      // this.setState({[menuState] : true});
      this.setState(function(prev){
        return {
          ...prev,
          sidebarPath:{
            ...prev.sidebarPath,
            [`${menuState}`]:true
          }
        }
      });
    }

  
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    
    
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state.sidebarPath).forEach(i => {
      // this.setState({[i]: false});
      this.setState(function(prev){
        return {
          ...prev,
          sidebarPath:{
            ...prev.sidebarPath,
            [`${i}`]:false
          }
        }
      });
    });

    const dropdownPaths = [
      {path:'/apps', state: 'appsMenuOpen'},
      {path:'/kantor', state: 'kantor'},
      {path:'/master-user', state: 'basicUiMenuOpen'},
      {path:'/advanced-ui', state: 'advancedUiMenuOpen'},
      {path:'/master-app', state: 'formElementsMenuOpen'},
      {path:'/transaksi', state: 'Transaksi'},
      {path:'/tables', state: 'tablesMenuOpen'},
      {path:'/maps', state: 'mapsMenuOpen'},
      {path:'/icons', state: 'iconsMenuOpen'},
      {path:'/charts', state: 'chartsMenuOpen'},
      {path:'/user-pages', state: 'userPagesMenuOpen'},
      {path:'/error-pages', state: 'errorPagesMenuOpen'},
      {path:'/general-pages', state: 'generalPagesMenuOpen'},
      {path:'/ecommerce', state: 'ecommercePagesMenuOpen'},
    ];

    dropdownPaths.forEach((obj => {
      if (this.isPathActive(obj.path)) {
        // this.setState({[obj.state] : true})
        this.setState(function(prev){
          return {
            ...prev,
            sidebarPath:{
              ...prev.sidebarPath,
              [`${obj.state}`]:true
            }
          }
        });
      }
    }));
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  role(){
    
    const wt = localStorage.getItem('wt')
    const Device_id = localStorage.getItem('d_i')
    const URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_BASE_URL_DEV : process.env.REACT_APP_BASE_URL;
    const url = URL+'auth/cekRole'
    const data = {}
    const headers = {
      headers : {
        Authorization : 'Bearer '+wt,
        Device_id : Device_id
    }
    }
    axios.post(url, data, headers)
    .then(res => {

      if(res.data.role === '1'){
        this.setState({isAdmin: true})
        
      }else if(res.data.role === '2'){
        this.setState({isCabang: true})
      }else if(res.data.role === '3'){
        this.setState({isSubcabang: true})
      }else{
        this.setState({isAdmin: false, isCabang:false})
      }
     
    })
    .catch(err=> console.log({err}))
  }


  render () {
  

    
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <a href="!#" className="nav-link" onClick={evt =>evt.preventDefault()}>
             
              <div className="nav-profile-text">
                <span className="font-weight-bold mb-2"><Trans>Admin</Trans></span>
                <span className="text-secondary text-small"><Trans>{this.props.state.username || "Null"}</Trans></span>
              </div>
              <i className="mdi mdi-bookmark-check text-success nav-profile-badge"></i>
            </a>
          </li>
          <li className={ this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/dashboard">
              <span className="menu-title"><Trans>Dashboard</Trans></span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          
          {
            this.state.isAdmin === true && <>
            <li className={ this.isPathActive('/kantor') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.kantor ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('kantor') } data-toggle="collapse">
              <span className="menu-title"><Trans>Kantor</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.kantor }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/kantor/cabang') ? 'nav-link active' : 'nav-link' } to="/kantor/cabang"><Trans>Cabang</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/kantor/subcabang') ? 'nav-link active' : 'nav-link' } to="/kantor/subcabang"><Trans>Sub Cabang</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/master-user') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Master User</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/admin') ? 'nav-link active' : 'nav-link' } to="/master-user/admin"><Trans>Admin Pusat</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/cabangadmin') ? 'nav-link active' : 'nav-link' } to="/master-user/cabangadmin"><Trans>Admin Cabang</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/subcabangadmin') ? 'nav-link active' : 'nav-link' } to="/master-user/subcabangadmin"><Trans>Admin Sub Cabang</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/driver') ? 'nav-link active' : 'nav-link' } to="/master-user/driver"><Trans>User Driver</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/customer') ? 'nav-link active' : 'nav-link' } to="/master-user/customer"><Trans>User Customer</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          </> }


          {
            this.state.isCabang === true && <>
            <li className={ this.isPathActive('/kantor') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.kantor ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('kantor') } data-toggle="collapse">
              <span className="menu-title"><Trans>Kantor</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.kantor }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/kantor/subcabang') ? 'nav-link active' : 'nav-link' } to="/kantor/subcabang"><Trans>Sub Cabang</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/master-user') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Master User</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.basicUiMenuOpen }>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/subcabangadmin') ? 'nav-link active' : 'nav-link' } to="/master-user/subcabangadmin"><Trans>Admin Sub Cabang</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/driver') ? 'nav-link active' : 'nav-link' } to="/master-user/driver"><Trans>User Driver</Trans></Link></li>

                <li className="nav-item"> <Link className={ this.isPathActive('/master-user/customer') ? 'nav-link active' : 'nav-link' } to="/master-user/customer"><Trans>User Customer</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          </> }
          
          {this.state.isSubcabang === true && 
            <>
                  <li className={ this.isPathActive('/master-user') ? 'nav-item active' : 'nav-item' }>
                    <div className={ this.state.sidebarPath.basicUiMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('basicUiMenuOpen') } data-toggle="collapse">
                      <span className="menu-title"><Trans>Master User</Trans></span>
                      <i className="menu-arrow"></i>
                      <i className=" mdi mdi-account-multiple-plus menu-icon"></i>
                    </div>
                    <Collapse in={ this.state.sidebarPath.basicUiMenuOpen }>
                      <ul className="nav flex-column sub-menu">

                        <li className="nav-item"> <Link className={ this.isPathActive('/master-user/driver') ? 'nav-link active' : 'nav-link' } to="/master-user/driver"><Trans>User Driver</Trans></Link></li>

                        <li className="nav-item"> <Link className={ this.isPathActive('/master-user/customer') ? 'nav-link active' : 'nav-link' } to="/master-user/customer"><Trans>User Customer</Trans></Link></li>
                      </ul>
                    </Collapse>
                  </li>
            </>
          }
        

          <li className={ this.isPathActive('/master-app') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.formElementsMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('formElementsMenuOpen') } data-toggle="collapse">
              <span className="menu-title"><Trans>Master App</Trans></span>
              <i className="menu-arrow"></i>
              <i className=" mdi mdi-chemical-weapon menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.formElementsMenuOpen }>
              <ul className="nav flex-column sub-menu">
                
                <li className="nav-item"> <Link className={ this.isPathActive('/master-app/bank') ? 'nav-link active' : 'nav-link' } to="/master-app/bank"><Trans>Bank Top-up</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/master-app/harga-jasa') ? 'nav-link active' : 'nav-link' } to="/master-app/harga-jasa"><Trans>Harga Jasa</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/master-app/kupon') ? 'nav-link active' : 'nav-link' } to="/master-app/kupon"><Trans>Voucher</Trans></Link></li>
                <li className="nav-item"> <Link className={ this.isPathActive('/master-app/skema-gaji') ? 'nav-link active' : 'nav-link' } to="/master-app/skema-gaji"><Trans>Skema Pendapatan Driver</Trans></Link></li>
              </ul>
            </Collapse>
          </li>
          <li className={ this.isPathActive('/pembayaran') ? 'nav-item active' : 'nav-item' }>
            <Link className="nav-link" to="/pembayaran">
              <span className="menu-title"><Trans>Pembayaran</Trans></span>
              <i className=" mdi mdi-calculator menu-icon"></i>
            </Link>
          </li>
         

          <li className={ this.isPathActive('/transaksi') ? 'nav-item active' : 'nav-item' }>
            <div className={ this.state.sidebarPath.Transaksi ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('Transaksi') } data-toggle="collapse">
              <span className="menu-title"><Trans>Transaksi</Trans></span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-tune menu-icon"></i>
            </div>
            <Collapse in={ this.state.sidebarPath.Transaksi }>
              <ul className="nav flex-column sub-menu">

                <li className="nav-item"> <Link className={ this.isPathActive('/transaksi/order') ? 'nav-link active' : 'nav-link' } to="/transaksi/order"><Trans>Order</Trans></Link></li>
                {this.state.isAdmin === true && <>
                    <li className="nav-item"> <Link className={ this.isPathActive('/transaksi/top-up-konfirmasi') ? 'nav-link active' : 'nav-link' } to="/transaksi/top-up-konfirmasi"><Trans>Top-up Konfirmasi</Trans></Link></li>
                  <li className="nav-item"> <Link className={ this.isPathActive('/transaksi/wd-konfirmasi') ? 'nav-link active' : 'nav-link' } to="/transaksi/wd-konfirmasi"><Trans>Withdraw Konfirmasi</Trans></Link></li>
                </>}
                
               
              </ul>
            </Collapse>
          </li>
         

          
        </ul>
      </nav>
    );
  }

 
}

const mapStateToProps = (state,ownProps) =>{

  return{
        state    
  }
}

// export default withRouter(Sidebar);
export default connect(mapStateToProps)(withRouter(Sidebar));